body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Assign color palette to semantic names */
  --color-primary: #073B4C; /* Example: a dark blue for primary actions */
  --color-secondary: #FF9B53; /* Example: an orange for secondary actions */
  --color-info: #118AB2; /* Example: a lighter blue for informational elements */
  --color-success: #06D6A0; /* Example: a green for success states */
  --color-warning: #FFD166; /* Example: a yellow for warnings */
  --color-danger: #FF595E; /* Example: a red for danger alerts or errors */
  --color-light: #FFEDDF; /* Example: a light peach for background or light elements */
  --color-dark: #303030; /* Example: a dark color for text or dark elements */
}